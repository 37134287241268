/*
 * @Author: 冯杰
 * @Date: 2021-10-12 20:33:14
 * @LastEditTime: 2021-10-29 20:00:50
 * @FileName: 经销商红包提现明细
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'DealerWithdraw',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/cashRecord/cashRecordReport/findDealerByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
  },
  methods: {
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'headPhotoIdCardPath') {
        console.log('rowData', rowData);
        rowData.type = 'html';
        rowData.formatter = ({ row }) => `<a href=${row.headPhotoIdCardPath}>下载</a>`;
      }
      return rowData;
    },
    modalClick({ val, row }) {
      let urls = '';
      let text = '';
      if (val.code === 'money') {
        urls = '/cps/v1/cashRecord/cashRecord/pay';
        text = '打款';
        if (row.id) {
          this.selection.push(row.id);
        }
        this.operationClick(urls, text);
      }
    },
    // 确认打款操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('dealer_withdraw');
  },
};
